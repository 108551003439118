// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint
} from '../../../common/edliy_utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfobox =false;
    JXG.Options.line.highlight=false;
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 12, 8, -4],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    brd1.suspendUpdate();
    // Layering
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Free Body Diagram', '(Tap on the objects in contact with the plank one by one.)');
    //dragMe(brd1, 5, 5);
    var stone=placeLine(brd1, [-2,0], [3,5], 10, 'grey');
    //
    var pts1 = [-15,0];
    var pts2 = [15,0];
    var pts3 = [15, -5];
    var pts4 = [-15, -5];
    var pts6= [-4, 6];
    var pts7 =[-4, 4];
    var i =0, j=0, k=0, l=0;
    //placeArrow(brd1, pts6, pts7, 'red');
    placeGravity(brd1, [-4,6],[-4, 4], 'g');
    var soil =placeImage(brd1,'/assets/floor.svg', -10, -20, 20., 0)
    //var ground =placeRec(brd1, pts1, pts2, pts3, pts4, 'grey');
    //var stone = placeImage(brd1,'/assets/stone.svg', -2, -0.5, 4, 0);
    var wall = placeImage(brd1,'/assets/wall.svg', 2, -0., 4., 0);
    var bull = placeImage(brd1,'/assets/bulls.svg', -5.25, -0.65, 4.0, 0);
    var dog =placeImage(brd1,'/assets/monkey1.svg', 0.25, 3, 1.5, 0);
    var shuffle=placeImage(brd1,'/assets/shuffle.svg', 3, 6, 2., 0)
    //var stone =brd1.create('segment',[[-2.,0],[3, 5]],{strokeColor:'grey', strokeWidth:10, highlight:false});
    var wallText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the wall on the  plank.');
    var bullText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the bull on the plank.');
    var dogText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the monkey on the plank.');
    var groundText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the floor on the plank');
    //placeText(brd1, 0, 8, '()=>'Total '+ (4-i-j-k-l).valueOf() +' objects remaining');
    brd1.create('text', [0., 9, ()=>'Total '+ (4-i-j-k-l).valueOf() +' objects remaining !'],{anchorX:'middle', display:'internal', highlight:false, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    wallText.setAttribute({visible:false});
    bullText.setAttribute({visible:false});
    dogText.setAttribute({visible:false});
    groundText.setAttribute({visible:false});
///////////////////////////////////////////////////////////
    hoverMe(brd1, stone, 'Plank', 0, -20);
    hoverMe(brd1, wall, 'Wall',0.5*wall.size[0], 0.5*wall.size[1]);
    hoverMe(brd1, dog, 'Monkey Mo', 0, +100);
    hoverMe(brd1, bull, 'Bull Bo', 0.5*bull.size[0], 0.5*bull.size[1]);
    hoverMe(brd1, soil, 'Ground', 0.5*soil.size[0], 0.9*soil.size[1]);
    hoverMe(brd1, shuffle,'Tap to Start Over!', 0,  -20)
    var Fwall =placeGravity(brd1, [4, 2],[2, 4], 'F_w');
    var Fdog = placeGravity(brd1, [1, 3],[1, 1], 'mg');
    var Fbull = placeGravity(brd1, [-3, 1.],[-1, 1.], 'F_B');
    var Fsoil1 = placeGravity(brd1, [-2, -2],[-2, 0], 'F_n');
    var Fsoil2 = placeGravity(brd1, [-2, 0],[0, 0], 'F_s');
    // Making bull invisible
    Fbull[0].setAttribute({visible:()=>i==1});
    Fbull[1].setAttribute({visible:()=>i==1});
/////////////////////////////////////////////////////////
    bull.setAttribute({visible:()=>i==0});
    bull.on('down', ()=>i=1);
//Making wall invisible
    Fwall[0].setAttribute({visible:()=>j==1});
    Fwall[1].setAttribute({visible:()=>j==1});
//////////////////////////////////////////////
    wall.setAttribute({visible:()=>j==0});
    wall.on('down', ()=>j=1);
///////////////////////////////////////////////////////
    Fdog[0].setAttribute({visible:()=>k==1});
    Fdog[1].setAttribute({visible:()=>k==1});
    dog.setAttribute({visible:()=>k==0});
    dog.on('down', ()=>k=1);
//////////////////////////////////////////////////////////////
    Fsoil1[0].setAttribute({visible:()=>l==1});
    Fsoil1[1].setAttribute({visible:()=>l==1});
    Fsoil2[0].setAttribute({visible:()=>l==1});
    Fsoil2[1].setAttribute({visible:()=>l==1});
    soil.setAttribute({visible:()=>l==0});
    soil.on('down', ()=>l=1);
//////////////////////////////////////////////////////////////
    shuffle.on('down', ()=>{i=0;j=0;k=0;l=0});
    clearInputFields(brd1);
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
